<template>
  <MenuView></MenuView>

	<section class="breadcrumb-progress">
		<nav class="breadcrumb is-centered" aria-label="breadcrumbs">
			<ul>
				<li><a href="#">1. Diagnostic</a></li>
				<li class="is-active"><a href="#">2. Your skin</a></li>
				<li><a href="#">3. Debriefing</a></li>
			</ul>
		</nav>
	</section>


	<p>To have a better understanding of your skin, here's a curated list of indicators:</p>

  <section class="results">
    <section>
      <div class="tile">
        <div class="fixed-grid has-2-cols">
          <div class="grid">
            <div class="cell has-text-left"> <p class="topic">Pores</p></div>
          </div>
        </div>
        <p class="description">Quantifies the size, visibility, and density of pores based on skin scans. It helps evaluate skin texture, assess pore-related concerns like clogging or oiliness, and track improvements from skincare treatments or products. </p>
      </div>

      <div class="score">
        <p class="score-detail has-text-centered">Your result: 12%</p>
      </div>
    </section>


    <section>
      <div class="tile">
        <div class="fixed-grid has-2-cols">
          <div class="grid">
            <div class="cell has-text-left"> <p class="topic">Redness</p></div>
          </div>
        </div>
        <p class="description">Numerical value that represents the intensity or distribution of redness, which may be caused by conditions such as irritation, inflammation, rosacea, or sunburn.</p>
      </div>
      <div class="score">
        <p class="score-detail has-text-centered">Your result: 59%</p>
      </div>
    </section>

    <section>
      <div class="tile">
        <div class="fixed-grid has-2-cols">
          <div class="grid">
            <div class="cell has-text-left"> <p class="topic">Acne</p></div>
          </div>
        </div>
        <p class="description">Measures the severity and extent of acne on the skin based on scans or visual analysis. It evaluates factors such as the number, size, and type of acne lesions (e.g., blackheads, whiteheads, cysts) </p>
      </div>
      <div class="score">
        <p class="score-detail has-text-centered">Your result: 2%</p>
      </div>
    </section>

    <section>
      <div class="tile">
        <div class="fixed-grid has-2-cols">
          <div class="grid">
            <div class="cell has-text-left"> <p class="topic">Hydration</p></div>
          </div>
        </div>
        <p class="description">Assesses the moisture levels in the skin, based on scans or specialized measurements. It provides an evaluation of skin hydration, identifying dryness or optimal moisture balance. </p>
      </div>
      <div class="score">
        <p class="score-detail has-text-centered">Your result: 98%</p>
      </div>
    </section>
  </section>

  <br>

  <router-link to="/debriefing">
    <button class="button button-primary-action is-fullwidth" @click="handleSubmit">
      <span>Get a quick debriefing!</span>
    </button>
  </router-link>
</template>

<script>
import MenuView from "@/components/MenuView.vue";

export default {
  name: "ResultView",
  components: { MenuView }
};
</script>

<style scoped>
.results .tile {
  margin: 2rem 0 0 0;
  background-color: #f4ede7;
  padding: 1.2rem 1.4rem !important;
}

.topic {
  color: #212121;
  font-weight: 700;
}

.score {
  margin: 0 0 0.3rem 0;
  background-color: #FFF;
  padding: 0.7rem 0;
  border: 0.3rem solid #f4ede7;
}

.score-detail {
  font-size: 1.3rem;
}

.score-indice p{
  font-size: 0.7rem;
}

.score-indice-color {
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 0.5rem;
  margin-left: auto;
	border-radius: 1rem;
}
</style>
