<template>
	<MenuView></MenuView>
	<section class="hero is-medium has-text-centered">
		<div class="hero-body">
			<h1 class="title">Your skin, your story.</h1>
			<p class="subtitle">Transform your skincare routine with a groundbreaking one-click skin analysis!</p>
			<p class="subtitle">Get precise diagnoses and personalized, independent recommendations tailored to your skin type, budget, lifestyle, and values.</p>
			<br>
			<p class="subtitle">Discover the perfect products for you today.</p>
			<br><br>
			<p class="subtitle coming-soon">We're opening soon!</p>
		</div>
	</section>
</template>

<script>
import MenuView from "@/components/MenuView.vue";

export default {
  name: 'LandingPageView',
	components: {MenuView}
}
</script>

<style scoped>
.title {
	font-size: 4rem;
	padding-bottom: 2rem;
	font-weight: 400;
}

.coming-soon {
	padding: 2rem 0;
	background: #9ABF9E;
	color: #FFF;
}

.is-fullheight {
	height: 100%;
}

</style>
