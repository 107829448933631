<template>
  <MenuView></MenuView>

	<section class="breadcrumb-progress">
		<nav class="breadcrumb is-centered" aria-label="breadcrumbs">
			<ul>
				<li><a href="#">1. Diagnostic</a></li>
				<li><a href="#">2. Your skin</a></li>
				<li class="is-active"><a href="#">3. Debriefing</a></li>
			</ul>
		</nav>
	</section>

  <section class="debriefing">
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sollicitudin a justo at congue. Ut metus ipsum, porta eget magna in, pretium pharetra lorem. Quisque maximus est non neque efficitur, sed vehicula urna tristique. Cras sit amet lectus non sapien feugiat accumsan. Duis dapibus risus ac ultrices rhoncus. Morbi vestibulum, purus eu mollis tincidunt, eros sapien egestas magna, eu aliquam metus mauris at leo. Curabitur convallis at ipsum eget faucibus.</p>
		<br>
		<p>Morbi vel cursus arcu. Ut sit amet scelerisque lacus. Quisque eros nisi, porta et mauris eu, ultrices tincidunt nibh. Quisque blandit est in vulputate iaculis. Fusce in velit tincidunt, elementum erat et, ultrices dui. Vivamus dolor augue, porttitor non lorem ac, iaculis volutpat massa. Maecenas justo quam, vulputate aliquet posuere sit amet, interdum sed ipsum. Sed quis fringilla neque. Nullam pharetra sollicitudin pretium. Nullam tincidunt risus hendrerit, cursus mi vitae, pellentesque orci. Vivamus diam odio, malesuada a placerat vitae, lacinia id mi. Fusce malesuada tincidunt dolor. Pellentesque luctus metus vel turpis pretium, vel lobortis sapien finibus. Phasellus sit amet urna ipsum. Etiam consequat felis nec augue auctor, condimentum dignissim lectus venenatis. Suspendisse vitae iaculis dui, id placerat orci.</p>
  </section>

  <br>

  <router-link to="/products">
    <button class="button button-primary-action is-fullwidth" @click="handleSubmit">
      <span>Let's find your best routine!</span>
    </button>
  </router-link>
</template>

<script>
import MenuView from "@/components/MenuView.vue";

export default {
  name: "DebriefingView",
  components: { MenuView }
};
</script>

<style scoped>
.results .tile {
  margin: 2rem 0 0 0;
  background-color: #f4ede7;
  padding: 1.2rem 1.4rem !important;
}

.topic {
  color: #212121;
  font-weight: 700;
}

.score {
  margin: 0 0 0.3rem 0;
  background-color: #FFF;
  padding: 0.7rem 0;
  border: 0.3rem solid #f4ede7;
}

.score-detail {
  font-size: 1.3rem;
}

.score-indice p{
  font-size: 0.7rem;
}

.score-indice-color {
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 0.5rem;
  margin-left: auto;
	border-radius: 1rem;
}
</style>
