<template>
  <MenuView></MenuView>
	<div class="container has-text-centered">
		<section class="headline">
			<h1>Welcome in your skincare universe</h1>
			<p>Create an account for free or login to discover your custom skincare routine</p>
		</section>

		<section class="oidc has-text-centered">
			<GoogleLogin :callback="callback"/>
		</section>
  </div>

</template>

<script setup>
import {decodeCredential} from "vue3-google-login";
import { useRouter } from "vue-router";
import MenuView from "@/components/MenuView.vue";

const router = useRouter();

const callback = async (response) => {
	const userData = decodeCredential(response.credential);

	globalState.name = userData.name || "Unknown User";
	globalState.email = userData.email || "Unknown email";
	globalState.email_verified = userData.email_verified || "Can't check if email verified";
	globalState.given_name = userData.given_name || "Unknown given name";
	globalState.family_name = userData.family_name || "Unknown family";

	const allowedEmails = ["jeremy@captaincy.io", "peggy.bouyon@gmail.com", "arthurbonnafon@gmail.com"];
	const isEmailAllowed = allowedEmails.includes(globalState.email);

	if (isEmailAllowed) {
		router.push({ name: "Welcome" });
	} else {
		router.push({ name: "WaitList" });
	}

	// try {
	// 	const functionsUrl = "https://us-central1-myuniqskin-sandbox.cloudfunctions.net/check_email_permission"
	// 	const checkEmailResponse = await axios.get(`${functionsUrl}?email=${globalState.email}`);
	//
	// 	if (checkEmailResponse.data.allowed) {
	// 		router.push({name: "Welcome"});
	// 	} else {
	// 		router.push({name: "WaitList"});
	// 	}
	// } catch (error) {
	// 	console.error("Error checking email:", error);
	// 	// Handle error, e.g., show an error message to the user
	// 	// For now, redirect to the WaitList in case of an error
	// 	router.push({name: "WaitList"});
	// }
};
</script>

<script>
import { reactive } from "vue";

export const globalState = reactive({
  name: null,
  given_name: null,
  family_name: null,
  email: null,
  email_verified: null,
});

export default {
  name: 'AuthView'
}
</script>

<style scoped>

.brand {
  margin: 2rem 0 7rem 0;
}

.title {
	padding-bottom: 1rem;
	font-family: "Pacifico", cursive;
	font-size: 2.7rem;
	font-weight: 400;
	font-style: normal;
	color: #302E28;
}

.subtitle {
	color: #B0B0B0;
	font-size: 1.25rem;
}

.headline {
	margin-bottom: 5rem;
}

.oidc {
  margin-bottom: 3rem !important;
}
</style>