<template>
  <nav class="navbar">
    <div class="has-text-centered">
			<img class="logo" src="assets/logo.png" alt="Logo"/>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MenuView'
}
</script>

<style scoped>
.logo {
  font-size: 1.4rem;
  padding: 0.6rem 0 2rem 0;
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
  color: #302E28;
}

.navbar {
  margin-bottom: 2.5rem;
  background: #FFF;
	border-bottom: 0.2rem solid #e3e3e3;
}

.navbar div {
	width: 100%;
}
</style>