<template>
 <MenuView></MenuView>
  <section class="hero">
    <div class="hero-body has-text-centered">
      <p v-if="user_given_name" class="title">{{ user_given_name }}, let's create your perfect routine!</p>
      <p v-if="!user_given_name" class="title">Let's create your perfect routine!</p>
    </div>
  </section>

	<section class="breadcrumb-progress">
		<nav class="breadcrumb is-centered" aria-label="breadcrumbs">
			<ul>
				<li class="is-active"><a href="#">1. Diagnostic</a></li>
				<li><a href="#">2. Your skin</a></li>
				<li><a href="#">3. Debriefing</a></li>
			</ul>
		</nav>
	</section>

  <section class="introduction">
    <p>First, a few questions about your habits:</p>
	</section>

  <section class="settings">
    <div class="settings-block">
        <h2>Budget</h2>
        <p>Click on the button that best describe your budget.</p>

        <div class="selector budget">
          <div class="fixed-grid has-3-cols">
            <div class="grid has-text-centered">
              <div class="cell button-secondary-action"><p>&lt; 20€</p></div>
              <div class="cell button-secondary-action"><p>20€ - 50€</p></div>
              <div class="cell button-secondary-action"><p>&gt; 50€</p></div>
            </div>
          </div>
        </div>
    </div>

    <div class="settings-block">
      <h2>Time (morning)</h2>
      <p>Click on the button that best describe the time you have for each routine.</p>

      <div class="selector time">
        <div class="fixed-grid has-3-cols">
          <div class="grid has-text-centered">
            <div class="cell button-secondary-action"><p>&lt; 10 min</p></div>
            <div class="cell button-secondary-action"><p>&lt; 20 min</p></div>
            <div class="cell button-secondary-action"><p>&gt; 20 min</p></div>
          </div>
        </div>
      </div>
    </div>

		<div class="settings-block">
			<h2>Time (night)</h2>
			<p>Click on the button that best describe the time you have for each routine.</p>

			<div class="selector time">
				<div class="fixed-grid has-3-cols">
					<div class="grid has-text-centered">
						<div class="cell button-secondary-action"><p>&lt; 10 min</p></div>
						<div class="cell button-secondary-action"><p>&lt; 20 min</p></div>
						<div class="cell button-secondary-action"><p>&gt; 20 min</p></div>
					</div>
				</div>
			</div>
		</div>

    <div class="settings-block">
      <h2>Organic products</h2>
      <p>Would you like to include <b>only</b> organic products in the results?</p>

      <div class="selector organic">
        <div class="fixed-grid has-2-cols">
          <div class="grid has-text-centered">
            <div class="cell button-secondary-action"><p>Yes</p></div>
            <div class="cell button-secondary-action"><p>No</p></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <router-link to="/scan">
    <button class="button button-primary-action is-fullwidth" @click="handleSubmit">
      <span>Last step, your scan</span>
    </button>
  </router-link>

  <br>
</template>

<script>
import { globalState } from "./AuthView";
import MenuView from "@/components/MenuView.vue";

export default {
  name: 'WelcomeView',
  setup() {
    return {
      user_name: globalState.name,
      user_given_name: globalState.given_name
    };
  },
	components: {MenuView}
}
</script>

<style scoped>
.introduction {
  color: #1E293B;
  margin-bottom: 2rem;
}

.settings-block {
  border-bottom: 0.2rem solid #e9ecef;
  padding: 1.2rem 0 2rem 0 !important;
  margin-bottom: 1rem;
}

.settings-block:last-child {
  margin-bottom: 2rem;
  border-bottom: none;
}

.selector {
  margin: 1.5rem 0 0.2rem 0;
}

.cell {
  padding: 0.5rem 0;
}

</style>