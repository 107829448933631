<template>
	<MenuView></MenuView>

	<section class="hero">
		<div class="hero-body has-text-centered">
			<p class="title">Now, scan your face!</p>
			<p class="subtitle">Follow the instructions to scan your face</p>
		</div>
	</section>

	<section class="breadcrumb-progress">
		<nav class="breadcrumb is-centered" aria-label="breadcrumbs">
			<ul>
				<li class="is-active"><a href="#">1. Diagnostic</a></li>
				<li><a href="#">2. Your skin</a></li>
				<li><a href="#">3. Debriefing</a></li>
			</ul>
		</nav>
	</section>


	<div class="container is-max-tablet has-text-centered">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img src="../assets/images/persona.jpg" alt="Placeholder image"/>
        </figure>
      </div>
    </div>

    <router-link to="/results">
      <button class="button button-primary-action is-fullwidth" @click="handleSubmit">
        <span>Discover your skin</span>
      </button>
    </router-link>

    <br><br><br><br><br><br><br>
  </div>
</template>

<script>
import MenuView from "@/components/MenuView.vue";

export default {
  name: 'ScanView',
	components: {MenuView}
};
</script>

<style scoped>
.hero-body {
  margin-bottom: 1rem;
}
</style>
