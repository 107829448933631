<template>
	<div class="content">
		<h1>Design system</h1>
		<p>This page aims to list every component available with their associated design.</p>
	</div>

	<br><br>

	<section>
		<p>Titles</p>
		<div class="demo-content">
			<h1>Title h1</h1>
			<h2>Title h2</h2>
			<h3>Title h3</h3>
			<h4>Title h4</h4>
			<h5>Title h5</h5>
			<h6>Title h6</h6>
		</div>
	</section>

	<section>
		<p>Buttons</p>
		<div class="demo-content">
			<button class="button button-primary-action is-fullwidth">This is a primary action button</button>
			<button class="button button-secondary-action is-fullwidth">This is a secondary action button</button>
		</div>
	</section>

	<section>
		<p>Colors</p>
		<div class="demo-content">

			<div class="columns">
				<div class="column"><div class="color-overview-block button-primary-action"></div></div>
				<div class="column is-four-fifths">Buttons - Primary action</div>
			</div>

			<div class="columns">
				<div class="column"><div class="color-overview-block button-secondary-action"></div></div>
				<div class="column is-four-fifths">Buttons - Secondary action</div>
			</div>

			<div class="columns">
				<div class="column"><div class="color-overview-block tile"></div></div>
				<div class="column is-four-fifths">Tile</div>
			</div>

			<div class="columns">
				<div class="column"><div class="color-overview-block"></div></div>
				<div class="column is-four-fifths">is-four-fifths</div>
			</div>

		</div>
	</section>

	<section>
		<p>Fonts</p>
		<div class="demo-content">
			<p>Font used for text is Figtree</p>
			<h5>Font used for titles (from h1 to h6) is Playfair Display</h5>
		</div>
	</section>
</template>

<script>
export default {
  name: 'DesignSystemView'
}
</script>

<style scoped>

section {
	margin-bottom: 1rem;
}

section > p {
	color: #b1b1b1;
	font-weight: 600;
	font-size: 1.5rem;
}

section > .demo-content {
	border: 3px solid #f6f6f6;
	padding: 2rem;
}

.color-overview-block {
	width: 3rem;
	height: 3rem;
}

.tile {
	background-color: #f4ede7;
}
</style>
