<template>
  <router-view />
</template>

<script>
import './assets/style.scss'

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: "Figtree", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-style: normal;
  padding-bottom: 1rem;
}

html, body {
  height: 100%;
  background-color: #ffffff;
  padding: 1rem;
  margin: 0 auto;
}

.mobile-container {
	max-width: 35rem;
}

p {
  font-size: 1.25rem;
}

.button {
  font-size: 1.1em;
  margin-bottom: 2rem;
  padding: 1rem 0;
}
/* LOGO */
.logo {
	width: 15rem;
}


/* TITLES */
h1, h2, h3, h4, h5, h6 {
	font-family: "Playfair Display", serif !important;
	font-optical-sizing: auto;
	font-style: normal;
}

h1 {
	font-size: 1.9em;
	font-weight: 500;
	color: #373737 !important;
	margin-bottom: 0.5rem;
}

h2 {
	font-size: 1.4em;
	font-weight: 500;
	color: #9f9f9f !important;
	margin-bottom: 0.5rem;
}

h3 {
	font-size: 1.3em;
	font-weight: 700;
	color: #676767 !important;
	margin-bottom: 0.5rem;
}

.title {
	color: #302E28;
	font-weight: 400;
}

.subtitle {
	color: #a5a5a5;
	font-weight: 500;
	margin-top: 0.7rem;
}

/* BUTTONS */
.button-primary-action {
	background-color: #212121;
	color: #F1F1F1 !important;
	border-radius: 0 !important;
}

.button-primary-action:hover {
	background-color: #737373;
}

.button-secondary-action {
	background-color: #9ABF9E;
	color: #FFF;
	border-radius: 0 !important;
}

.button-secondary-action:hover {
	background-color: #77a17c;
	color: #FFF;
}

/* breadcrumb */
.breadcrumb-progress {
	padding: 1rem 0;
	margin-bottom: 2rem;
}

.breadcrumb a {
	color: #474747 !important;
}

.breadcrumb li.is-active {
	font-weight: 700;
}

</style>
