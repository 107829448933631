import { createWebHistory, createRouter } from 'vue-router'

import UserFaceScanView from '@/components/ScanView.vue'
import ResultView from '@/components/ResultView.vue'
import ProductView from '@/components/ProductView.vue'
import WelcomeView from "@/components/WelcomeView.vue";
import AuthView from "@/components/AuthView.vue";
import WaitListView from "@/components/WaitListView.vue";
import DesignSystemView from "@/components/DesignSystemView.vue";
import LandingPageView from "@/components/LandingPageView.vue";
import DebriefingView from "@/components/DebriefingView.vue";

const routes = [
    { path: '/', component: LandingPageView, name: 'LandingPage' },
    { path: '/auth', component: AuthView, name: 'Auth' },
    { path: '/scan', component: UserFaceScanView, name: 'Scan' },
    { path: '/results', component: ResultView, name: 'Results' },
    { path: '/welcome', component: WelcomeView, name: 'Welcome' },
    { path: '/waitlist', component: WaitListView, name: 'WaitList' },
    { path: '/products', component: ProductView, name: 'Products' },
    { path: '/debriefing', component: DebriefingView, name: 'Debriefing' },
    { path: '/internal/design-system', component: DesignSystemView, name: 'DesignSystem' },
    { path: '/:catchAll(.*)', redirect: '/' }, // Redirect unknown routes.
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

export default router