import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import vue3GoogleLogin from 'vue3-google-login'

createApp(App)
    .use(router)
    .use(vue3GoogleLogin, {
        clientId: '772383735972-4m0brfhpllvimeplqg2m2t913tvgifqo.apps.googleusercontent.com'
    })
    .mount('#app')
