<template>
  <MenuView></MenuView>
  <h1>We're not opened yet!</h1>
  <br>
	<p>Hello {{ user_name }}, thank you for your interest! We're not opened yet but we can let you know when the times come.</p>
</template>

<script>
import MenuView from "@/components/MenuView.vue";
import { globalState } from "./AuthView";

export default {
  name: "WaitListView",
  components: { MenuView },
	setup() {
		return {
			user_name: globalState.name,
			user_given_name: globalState.given_name
		};
	}
};
</script>