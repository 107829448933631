<template>
  <MenuView></MenuView>

	<p>After analyzing the results of your facial scan, we have designed a personalized routine specifically tailored to your needs, addressing exactly what your face requires.</p>
  <br>
  <h1>Your morning routine</h1>
  <br>

	<section class="product-list">
		<section class="product" v-for="(product, index) in matching" :key="index">
			<section class="product-content">
				<section>
					<h4>{{ product.title }}</h4>
					<p>{{ product.brand }}</p>
					<br>
					<p>{{ product.description }}</p>
				</section>
			</section>

			<section class="product-hero">
				<img :src="'assets/products/' + product.brand_slug + '/' + product.sku + '.png'" :alt="product.title" />
<!--				<div class="product-call-to-action">-->
<!--					<div class="price">{{ product.price }}</div>-->
<!--				</div>-->
			</section>

			<section class="product-content">
				<section>
					<h5>Key ingredients</h5>

					<section v-for="(ingredient, i) in product.ingredients.key" :key="i">
						<p><b>{{ ingredient.title }}</b>: {{ ingredient.description }} </p>
					</section>
				</section>

				<hr>

				<section>
					<h5>Why do you need it?</h5>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ante a massa consequat rhoncus, ut sit amet sodales augue.</p>
				</section>
			</section>

			<section class="product-data-sheet">
				<h5>Product data sheet</h5>

				<p>Brand: {{ product.brand }}</p>
				<p>Quantity: {{ product.size }}</p>
				<p>Rating: X/10</p>
				<p>Price: {{ product.price }}</p>
				<p>Ingredients list: click here to display.</p>
				<br>

				<button class="button-add-to-basket">Add to basket</button>
			</section>

			<div class="has-text-centered product-separator">
				<img src="assets/icons/arrow-down-solid.svg" alt="next product">
			</div>

		</section>
	</section>

  <br><br>
</template>

<script>
import axios from "axios";
import MenuView from "@/components/MenuView.vue";

export default {
  name: "ProductView",
  components: { MenuView },
  data() {
    return {
      matching: [],
    };
  },
  methods: {
    async getProducts() {
      try {
        const { data } = await axios.get("https://api.match-my-skin.com/matching", {
          headers: {
            Authorization: "TntVFctiZzq74GJudz9PJH2F3N5rZUHiWXcOjYgS",
          },
        });
        this.matching = data.products || [];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
  },
  beforeMount() {
    this.getProducts();
  },
};
</script>

<style scoped>
h1 {
  font-weight: 600;
  font-style: normal;
  font-size: 1.25rem;
  color: #e9c46a;
}

h4 {
  font-weight: bold;
	color: #212121;
  font-size: 1.4rem;
}

h5 {
  color: #a19980;
	font-size: 1.2rem;
  font-weight: 600;
	margin-bottom: 0.4rem;
}

.product:last-child .product-separator {
	display: none;
}

.product-hero {
	position: relative;
	margin-bottom: 1rem;
}

.product-hero img {
	object-fit: contain;
	height: 100%;
	width: 100%;
}

.product-content {
	background-color: #f2e8cf;
	padding: 1.3rem;
	margin-bottom: 1rem;
}

.product-data-sheet {
	background-color: #f6f6f6;
	padding: 1.3rem;
	margin-bottom: 1rem;
}

.product-data-sheet h5 {
	color: #212121;
}

.product-separator {
	margin: 2rem 0;
}

.product-separator img {
	width: 1.5rem;
	height: 1.5rem;
}

.product-call-to-action .price {
	position: absolute;
	bottom: 1.5rem;
	right: 1.2rem;
	background-color: rgba(0, 0, 0, 0.1);
	color: #212121;
	font-weight: 600;
	padding: 0.6rem 0.9rem;
	font-size: 1.2rem;
	z-index: 2;
}

hr {
	background-color: #d1c8ac;
}

</style>
